<template>
  <b-card class="login-body mb-5 mt-8">
    <h1>Användarvillkor för Webbtjänsten "Memlist"</h1>

    <h2>1. Inledning</h2>
    <p>
        Dessa användarvillkor (nedan kallade "Villkoren") reglerar din användning av webbtjänsten "Memlist"
        (nedan kallad "Webbtjänsten"), som ägs och drivs av ThinkingHat Studios AB
        (nedan kallad "Vi" eller "Oss").
    </p>

    <h2>2. Åtkomst och Registrering</h2>
    <p>
        2.1 Ålderskrav: Endast personer som är minst 18 år eller äldre får registrera sig som användare på Webbtjänsten. Om du är under 18 år måste du ha föräldrarnas eller vårdnadshavarens samtycke innan du använder Webbtjänsten.
    </p>
    <p>
        2.2 Registrering: För att få full tillgång till funktionerna på Webbtjänsten måste du registrera dig som användare. Vid registreringen måste du tillhandahålla sanna, korrekta och aktuella uppgifter om dig själv.
    </p>
    <p>
        2.3 Användaruppgifter: Efter att du har registrerat dig kommer du att tilldelas ett användarnamn och lösenord. Du är ansvarig för att hålla ditt lösenord säkert och inte dela det med någon annan. Om du misstänker att någon obehörig har fått tillgång till dina inloggningsuppgifter, måste du omedelbart meddela oss.
    </p>

    <h2>3. Personuppgifter och Föreningar</h2>
    <p>
        3.1 Insamling av personuppgifter: Som en del av registreringsprocessen och användning av Webbtjänsten kan du bli ombedd att lämna personliga uppgifter och uppgifter om föreningar. Vi behandlar all insamlad information enligt vår sekretesspolicy.
    </p>
    <p>
        3.2 Ansvar för uppgifter: Som användare ansvarar du för att de uppgifter du lämnar om dig själv och föreningar är korrekta, aktuella och rättvisa.
    </p>
    <p>
        3.3 Dataskydd: Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att skydda dina personuppgifter och föreningars uppgifter mot obehörig åtkomst eller olaglig behandling. För mer information om hur vi hanterar personuppgifter, vänligen se vår sekretesspolicy.
    </p>

    <h2>4. Användarbeteende och Ansvar</h2>
    <p>
        4.1 Korrekt användning: Du samtycker till att använda Webbtjänsten endast för lagliga ändamål och i enlighet med dessa Villkor.
    </p>
    <p>
        4.2 Användaransvar: Du är ensam ansvarig för all information, data och innehåll som du laddar upp, skapar eller förvaltar på Webbtjänsten. Du samtycker till att inte ladda upp eller dela olagligt, stötande eller kränkande material.
    </p>
    <p>
        4.3 Licens: Genom att ladda upp, dela eller förvalta innehåll på Webbtjänsten ger du oss en icke-exklusiv, global, royaltyfri licens att använda, återge, anpassa och publicera ditt innehåll i samband med driften av Webbtjänsten och våra tjänster.
    </p>

    <h2>5. Ändringar av Användarvillkor</h2>
    <p>
        5.1 Uppdatering av Villkoren: Vi förbehåller oss rätten att när som helst ändra eller uppdatera dessa Villkor. Om vi gör väsentliga ändringar kommer vi att meddela dig via e-post eller på Webbtjänsten. Genom att fortsätta använda Webbtjänsten efter att ändringar har gjorts godkänner du de uppdaterade Villkoren.
    </p>

    <h2>6. Avslutning av Användarkonto</h2>
    <p>
        6.1 Avslutning av användarkonto: Du kan när som helst avsluta ditt användarkonto genom att kontakta oss. Vi kan också avsluta ditt konto om du bryter mot dessa Villkor eller om det krävs enligt lag.
    </p>

    <h2>7. Kontaktinformation</h2>
    <p>
        Om du har några frågor, klagomål eller förfrågningar om dessa Villkor eller Webbtjänsten, vänligen kontakta oss på support@memlist.se.
    </p>

    <p>
        Genom att logga in som användare på Webbtjänsten bekräftar du att du har läst och accepterat dessa användarvillkor och att du samtycker till att vara bunden av dem under din användning av Webbtjänsten som en registrerad användare.
    </p>
  </b-card>
</template>

<style lang="scss" scoped>

</style>

<script>
import { mapState } from 'vuex';

import { validationMixin } from 'vuelidate';

export default {
  mixins: [validationMixin],
  name: 'terms',
  data() {
    return {
     
    };
  },
  
  mounted() {

  },
  methods: {
    
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
